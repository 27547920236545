@media only screen and (min-width: 1200px) {
  .bgdivparent {
    display: none;
  }
  .iframeDiv {
    display: block;
    background-color: black;
    display: flex;
    flex-direction: column;
  }
  .iframePositionDiv {
    width: auto;
    position: fixed;
    top: 20%;
  }
  .iframePositionDivInnerDiv1 {
    width: 50px;
    height: 50px;
    margin-bottom: 1vh;
  }
  .iframePositionDivInnerDiv1 > img {
    cursor: pointer;
  }
  .iframePositionDivInnerDiv {
    width: 50px;
    height: 50px;
  }
  .iframePositionDivInnerDiv > img {
    cursor: pointer;
  }
  .updatesandAdsDiv {
    background-color: black;
  }
  .updatesandAdsInnerDiv {
    background-color: slategrey;
    padding: 1vh;
    color: black;
  }
  .rightSideAdsMainDiv {
    width: 100vw;
    height: auto;
    color: black;
    padding: 3vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
  }
  .rightSideAdsMainDiv > div {
    background-color: slategrey;
    width: 15vw;
    height: 30vw;
    padding: 1vh;
  }
  iframe {
    height: 40vw;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bgdivparent {
    display: none;
  }
  /* .iframeDiv {
    display: none;
  }
  .iframe {
    display: block;
  }
  .updatesandAdsDiv {
    display: none;
  } */
  .iframeDiv {
    display: block;
    background-color: black;
    display: flex;
    flex-direction: column;
  }
  .iframePositionDiv {
    width: auto;
    position: fixed;
    top: 20%;
  }
  .iframePositionDivInnerDiv1 {
    width: 50px;
    height: 50px;
    margin-bottom: 1vh;
  }
  .iframePositionDivInnerDiv1 > img {
    cursor: pointer;
  }
  .iframePositionDivInnerDiv {
    width: 50px;
    height: 50px;
  }
  .updatesandAdsDiv {
    background-color: black;
  }
  .updatesandAdsInnerDiv {
    background-color: slategrey;
    padding: 1vh;
    color: black;
  }
  iframe {
    width: 60vw;
    height: 40vw;
  }
  .rightSideAdsMainDiv {
    width: 100vw;
    height: auto;
    color: black;
    padding: 3vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
  }
  .rightSideAdsMainDiv > div {
    background-color: slategrey;
    width: 15vw;
    height: 30vw;
    padding: 1vh;
  }
}
/* write css */
@media only screen and (max-width: 820px){
  .is_dark .logo-dark {
    /* display: block; */
    width: 6vh;
    height: 6vh;
}
  .slidercss{
    display: none;
  }
  .iframeDiv{
    display: none;
  }
  .updatesandAdsDiv{
    display:none
  }
  .bgdivparent{
    display: block;
  }
  .bgdiv {
     background-image: url("https://chessverse.b-cdn.net/back3backssmall.webp"); 
    background-color:  #001E3F;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
  }
  .bgdivinside1 >img{
    height: 48px;
    width: 170px;
    margin-left: 30px;
  }
  .bgdivinside {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  .bgdivinside > img {
    width: auto;
    height: 10rem;
    border-radius: 10px;
    margin: 40px;
    cursor: pointer;
  }
  .text-style{
    font-size: 15px;
    font-family: "monospace";
    margin-bottom: 20px;
    /* margin-left: 20px; */
  }
  .divofcarousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .displayforslider {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .bgdivparent {
    display: none;
  } */
  /* .slidercss{
    display: none;
  } */
  .updatesandAdsDiv {
    background-color: black;
  }
  .updatesandAdsInnerDiv {
    background-color: slategrey;
    padding: 1vh;
    color: black;
  }
  .rightSideAdsMainDiv {
    width: 100vw;
    height: auto;
    color: black;
    padding: 3vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
  }
  .rightSideAdsMainDiv > div {
    background-color: slategrey;
    width: 15vw;
    height: 30vw;
    padding: 1vh;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bgdivparent {
    display: none;
  }
  .updatesandAdsDiv {
    background-color: black;
  }
  .updatesandAdsInnerDiv {
    background-color: slategrey;
    padding: 1vh;
    color: black;
  }
  iframe {
    height: 40vw;
  }
  .rightSideAdsMainDiv {
    width: 100vw;
    height: auto;
    color: black;
    padding: 3vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
  }
  .rightSideAdsMainDiv > div {
    background-color: slategrey;
    width: 15vw;
    height: 30vw;
    padding: 1vh;
  }
}
@media only screen and (max-width: 600px) {
 
  .bgdiv {
     background-image: url("https://chessverse.b-cdn.net/back3backssmall.webp"); 
    background-color:  #001E3F;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
  }
  .text-style{
    font-size: 20px;
    font-family: "monospace"
  }
  /* .bgdivinside {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  } */
  .bgdivinside1 >img{
    height: 52px;
    width: 150px;
    margin-left: 30px;
  }
  .bgdivinside2{
    height: 48px;
  }
  /* .bgdivinside2 >img{
   height: 40px;
  } */
 .image-css{
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
 }
 /* .bgdivinside1{
  height: 300px;
  width: 100px;
 }
 .bgdivinside2{
  height: 250px;
  width: 100px;
 } */
  .bgdivinside > img {
    width: auto;
    height: 5rem;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
  }
  .divofcarousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .displayforslider {
    display: none;
  }
  .iframeDiv {
    display: none;
  }
  .iframe {
    display: block;
  }
  .updatesandAdsDiv {
    display: none;
  }
}

/* @media only screen and (min-width:768px) and (max-width:1180px) and (orientation:landscape){
  .iframe{
    display: none;
  }
} */

@media only screen and (width: 1180px) and (height:820px){
  .iframeDiv{
    display: none;
  }
  .updatesandAdsDiv{
    display: none;
  }
  .image-css{
    display: flex;
    justify-content: space-between;
    
   }
  .bgdivinside1 >img {
    height: 73px;
    width:80%;
    margin-left: 30px;
    margin-top: 1px;
}
.bgdivinside2{
  height: 60px;
  width: 180px;
}
/* .bgdivinside2 >img{
  height: 190px;
  width: 80%;
} */
.text-style{
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
}
}
@media (max-width: 767px) {
  .is_dark .logo-dark {
      height: 75px !important;
      width: inherit;
  }
}

.showMoreMainDiv {
  padding: 2vh;
  margin-top: 4vh;
  margin-bottom: 4vh;
  box-shadow: 10px 10px 10px 5px rgba(0, 0, 0, 0.5);
  border-radius: 2vh;
}
.showMoreMainDiv:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}
.para {
  font-size: larger;
  font-family: sans-serif;
}

@media only screen and (max-width: 600px) {
  .sliderDisplay {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .playBtn {
    background: url("https://chessverse.b-cdn.net/PLAYBUTTONbeta5.webp");
    background-position: cover;
    background-repeat: no-repeat;
    width: 65vh;
    height: 15vh;
    border: none !important;
    position: relative;
    top: 30vh;
  }
  .playBtn:active {
    transform: translateY(15px);
  }

  .profmoimage {
    height: 350px;
    width: 350px;
    animation: mover 2s infinite alternate;
  }
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-30px);
    }
  }
}
@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1200px) {

  
  .playBtn {
    background: url("https://chessverse.b-cdn.net/PLAYBUTTONbeta5.webp");
    background-position: cover;
    background-repeat: no-repeat;
    width: 40vw;
    height: 20vh;
    left: 5vw;
    border: none;
    /* border: 1px solid white; */
    position: relative;
    top: 40vh;
 }
  
  .playBtn:active {
    transform: translateY(15px);
  }
  .profmoimage {
    height: 350px;
    width: 350px;
    animation: mover 2s infinite alternate;
  }
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
}

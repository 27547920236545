@media only screen and (max-width: 600px) {
.storywrapper{
  text-justify:'inter-word';
  text-align:justify;
  padding-left: 2vh;
  padding-right: 2vh;
}
.videowrapper{
  display: flex;
  justify-content: center;
  padding-left: 5vh;  
}
.storywrapper>p{
  font-size: large;
  line-height: 5vh;
  font-family: Peppermint, sans-serif
}  
}
@media only screen and (min-width: 992px) {
  .videowrapper{
    display: flex;
    justify-content: center;
    padding-right: 40vh;
  }  
  .storywrapper{
    text-justify:'inter-word';
    text-align:justify;
    padding-left: 8vh;
    padding-right: 2vh;
  }
  .storywrapper>p{
    font-size: large;
    line-height: 5vh;
    font-family: Peppermint, sans-serif
  }
}
@media (min-width: 481px) and (max-width: 850px){
  .videowrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16vh;
  }  
  .storywrapper{
    text-justify:inter-word;
    text-align:justify;
    padding-left: 3vh;
    padding-right: 2vh;
  }
  .storywrapper>p{
    font-size: x-large;
    line-height: 5vh;
    font-family: Peppermint, sans-serif
  }
}

@media only screen and (min-width: 1200px) {
  .puzzleLabelsContainer {
    position: absolute;
    top: 15vh;
    margin-left: 10vh;
 
    /* display: flex;
    justify-content: center;
    width: 100%;
    padding: 2vh;
    gap: 5vh; */
  }
  .puzzleLabelsContainer>h3,p{
    
    font-size: 20px;
    font-family: "gangofthree";
  }
  .puzzleMainContainer {
    position: relative;
    margin: 0 auto;
    /* top: 25vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vh;
    gap: 5vh;
  }
  .puzzlewarapper {
    width: 30vw;
  }
  .optionsclass {
    display: flex;
    flex-direction: column;
    gap: 5vh;
  }
  .optionsclass > select {
    width: auto;
    height: 50px;
  }
  .getOptionsBtn {
    margin-bottom: 5vh;
  }

  .btnDiv {
    margin-top: 5vh;
  }
  .hoverlist:hover {
    background-color: black;
    color: white;
  }
  .footer{
    position: absolute;
    top: 150vh;
  }
}
@media only screen and (max-width: 850px) {
  .puzzleLabelsContainer {
    position: absolute;
    top: 15vh;
    margin-left: 5vh;
    font-family: "gangofthree";
    text-justify: inter-word;
    text-align: justify;
    padding: 5vh;
    /* display: flex;
    gap: 5vh;
    flex-direction: column; */
    /* margin-right: 5vh; */
    /* display: flex;
    justify-content: center;
    width: 100%;
    padding: 2vh;
    gap: 5vh; */
  }
  .puzzleLabelsContainer>h3,p{
    font-size: xx-large;
    font-family: "gangofthree";
  }
  .puzzleMainContainer {
    position: relative;
    /* top: 40vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5vh;
  }
  .puzzlewarapper {
    width: 90vw;
    padding: 2vh;
  }
  .optionsclass {
    display: flex;
    flex-direction: column;
    gap: 5vh;
  }
  .optionsclass > select {
    width: auto;
    height: 90px;
    font-size: x-large;
  }
  .getOptionsBtn {
    margin-bottom: 5vh;
    font-size: x-large;
  }

  .btnDiv {
    margin-top: 5vh;
    width: 100%;
    font-size: x-large;
  }
}
@media only screen and (max-width: 600px) {
  .puzzleLabelsContainer {
    position: absolute;
    top: 15vh;
    margin-left: 10vh;
    text-justify: inter-word;
    text-align: justify;
    /* display: flex;
    justify-content: center;
    width: 100%;
    padding: 2vh;
    gap: 5vh; */
    font-family: "gangofthree";
  }
  .puzzleMainContainer {
    position: relative;
    margin: 0 auto;
    top:0vh
    /* top: 25vh; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    width: 100vh;
    gap: 5vh; */

  }
  .puzzlewarapper {
    width: 100%;
    padding: 2vh;
    /* margin-top: 15vh; */
  }
  .optionsclass {
    display: flex;
    flex-direction: column;
    gap: 5vh;
  }
  .optionsclass > select {
    width: auto;
    height: 50px;
  }
  .getOptionsBtn {
    margin-bottom: 5vh;
  }

  .btnDiv {
    margin-top: 5vh;
    width: 100%;
    color: black;
  }
}

.mainDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.containerWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 5%;
}

.videos-grid {
  max-width: 800px; /* Adjust the max-width as needed */
  width: auto;
  margin: 0 auto;
}

.video-container {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-player {
  width: 100%;
}

.video-text {
  background-color: #f0f0f0;
  padding: 10px;
}

.video-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.video-description {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .containerWrapper {
    margin-top: 20%;
  }
}

.react-multi-carousel-item {
  width: 400px !important;
}
.paginationDiv {
  display: flex;
  font-family: sans-serif;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2vh;
}

@media only screen and (max-width: 600px) {
    .textSection{
        text-justify:inter-word;
        text-align:justify;
    }
    .textSection>p{
        font-family: Peppermint, sans-serif;
        font-size: 20px;
    }
}
@media (min-width: 481px) and (max-width: 850px){
    .textSection{
        text-justify:inter-word;
        text-align:justify;
    }
    .textSection>p{
        font-family: Peppermint, sans-serif;
        line-height: 4vh;
        font-size: xx-large;
    }
}
@media only screen and (min-width: 992px) {
    .textSection{
        text-justify:inter-word;
        text-align:justify;
    }
    .textSection>p{
        font-family: Peppermint, sans-serif;
        line-height: 5vh;
        font-size: 20px;
    }
}